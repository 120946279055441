import React from "react";
import "./MOT.css";
import "../AboutUsSection/AUS.css";
import "../SocialMedia/SocialMedia.css";

import { Link } from "react-router-dom";
import { GrGroup } from "react-icons/gr";
import { images } from "../../constants";
import { SubHeading } from "../../components";

const MOT = () => {
  return (
    <div className="app__socialMedia section__padding" id="mot">
      <div className="app__socialMedia-title">
        <h1 className="headtext__cormorant">Meet Our Team</h1>
      </div>

      <div className="app__social_media__container">
        <div style={{ zIndex: "1" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <p
              className="p__opensans "
              style={{
                color: "white",
                fontSize: "15px",
                lineHeight: "2",
                justifyContent: "center",
              }}
            >
              Sangeet Tales is a pool of passionate and talented folks who love
              what they do and who always strive to do the best for their
              clients!!
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <p
              className="p__opensans glow__text"
              style={{
                color: "white",
                fontSize: "15px",
                lineHeight: "2",
              }}
            >
              Click on the picture to meet our wonderful people!
            </p>
          </div>
          <Link to="/team#team__head">
            <img className="mot__style" src={images.mot} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MOT;
