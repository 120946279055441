/* eslint-disable import/no-anonymous-default-export */
import bg from "../assets/bg.png";
import overlaybg from "../assets/overlaybg.png";
import spoon from "../assets/spoon.svg";
import quote from "../assets/quote.png";
import sangeetTales from "../assets/sangeetTales.png";
import sangeettales_banner from "../assets/sangeettales_banner.png";
import sangeettales_bg from "../assets/thesangeettales_bg.png";
import sangeettales_varsha from "../assets/sangeettales_varsha.png";
import sangeettales_subbu from "../assets/sangeettales_subbu.png";
import sangeettales_review from "../assets/sangeettales_review.png";
import team_sangeetales from "../assets/team_sangeettales.jpg";
import mot from "../assets/mot.png";
import fb_logo_st from "../assets/facebook.png";
import insta_logo_st from "../assets/insta.png";
import yt_logo_st from "../assets/youtube.png";

//Gallery section images
import sangeettales_01 from "../assets/sangeettales_01.png";
import sangeettales_02 from "../assets/sangeettales_02.png";
import sangeettales_03 from "../assets/sangeettales_03.png";
import sangeettales_04 from "../assets/sangeettales_04.png";
import sangeettales_05 from "../assets/sangeettales_05.png";
import sangeettales_06 from "../assets/sangeettales_06.png";
import sangeettales_07 from "../assets/sangeettales_07.png";

//video partner
import rv_vd_sangeettales from "../assets/rv_vd_sangeettales.png";

//digital partners
import madhu_dp_sangeettales from "../assets/Madhu_dp_sangeettales.png";
import suda_dp_sangeettales from "../assets/Suda_dp_sangeettales.png";

//DJ
import dj_lash_sangeettales from "../assets/lash_dj_sangeettales.png";
import dj_sanjay_sangeettales from "../assets/sanjay_dj_sangeettales.png";

//Trainers
import shruthi_tc_sangeettales from "../assets/shruthi_tc_sangeettales.png";
import sneha_tc_sangeettales from "../assets/sneha_tc_sangeettales.png";
import preethi_tc_sangeettales from "../assets/preethi_tc_sangeettales.png";
import sandip_tc_sangeettales from "../assets/sandip_tc_sangeettales.png";
import niranjani_tc_sangeettales from "../assets/niranjani_tc_sangeettales.png";
import kaavya_tc_sangeettales from "../assets/kaavya_tc_sangeettales.png";
import snehasriram_tc_sangeettales from "../assets/snehasriram_tc_sangeettales.png";
import ramyaa_tc_sangeettales from "../assets/ramyaa_tc_sangeettales.png";
import nikhil_tc_sangeettales from "../assets/nikhil_tc_sangeettales.png";
import vishal_tc_sangeettales from "../assets/vishal_tc_sangeettales.png";

//MC
import aasha_mc_sangeettales from "../assets/aasha_mc_sangeettales.png";

//DS
// import Varun_sd_sangeettales from "../assets/Varun_sd_sangeettales.png";
// import sabarish_sd_sangeettales from "../assets/sabarish_sd_sangeettales.png";
import sabarish_varun from "../assets/sabarish_varun.png";

//Makeup
import harini_mk_sangeettales from "../assets/harini_mk_sangeettales.png";
import preksha_mk_sangeettales from "../assets/preksha_mk_sangeettales.png";

//Main Gallery
import mg_sangeetales_1 from "../assets/1.png";
import mg_sangeetales_2 from "../assets/2.png";
import mg_sangeetales_3 from "../assets/3.png";
import mg_sangeetales_4 from "../assets/4.png";
import mg_sangeetales_5 from "../assets/5.png";
import mg_sangeetales_6 from "../assets/6.png";
import mg_sangeetales_7 from "../assets/7.png";
import mg_sangeetales_8 from "../assets/8.png";
import mg_sangeetales_9 from "../assets/9.png";
import mg_sangeetales_10 from "../assets/10.png";
import mg_sangeetales_11 from "../assets/11.png";
import mg_sangeetales_12 from "../assets/12.png";
import mg_sangeetales_13 from "../assets/13.png";
import mg_sangeetales_14 from "../assets/14.png";
import mg_sangeetales_15 from "../assets/15.png";
import mg_sangeetales_16 from "../assets/16.png";
import mg_sangeetales_17 from "../assets/17.png";
import mg_sangeetales_18 from "../assets/18.png";
import mg_sangeetales_19 from "../assets/19.png";
import mg_sangeetales_20 from "../assets/20.png";
import mg_sangeetales_21 from "../assets/21.png";
import mg_sangeetales_22 from "../assets/22.png";
import mg_sangeetales_23 from "../assets/23.png";
import mg_sangeetales_24 from "../assets/24.png";
import mg_sangeetales_25 from "../assets/25.png";
import mg_sangeetales_26 from "../assets/26.png";
import mg_sangeetales_27 from "../assets/27.png";
import mg_sangeetales_28 from "../assets/28.png";
import mg_sangeetales_29 from "../assets/29.png";
import mg_sangeetales_30 from "../assets/30.png";
import mg_sangeetales_31 from "../assets/31.png";
import mg_sangeetales_32 from "../assets/32.png";
import mg_sangeetales_33 from "../assets/33.png";
import mg_sangeetales_34 from "../assets/34.png";
import mg_sangeetales_35 from "../assets/35.png";
import sangeettales_banner_1 from "../assets/sangeettales_banner_1.png";

//wcst
import wcst from "../assets/wcst.png";

//Social Media
import instagramProfile from "../assets/Instagram Profile.png";
import youtubeProfile from "../assets/youtubeProfile.png";
import facebookProfile from "../assets/facebookProfile.png";

//Articles
import toi from "../assets/toi.png";
import tnie from "../assets/tie.png";

// intro video
import introvideo from "../assets/video.mp4";

//contactUs
import thesangeettales_contact_us from "../assets/thesangeettales_contact_us.png";
import footer_web from "../assets/footer_web.png";
import footer_mob from "../assets/footer_mob.png";

//review
import pankaj_review from "../assets/pankaj_review.png";
import varuna_review from "../assets/varuna_review.png";
import sunithra_review from "../assets/sunithra_review.png";

export default {
  mot,
  bg,
  overlaybg,
  spoon,
  quote,
  sangeetTales,
  sangeettales_banner,
  sangeettales_banner_1,
  sangeettales_bg,
  sangeettales_varsha,
  sangeettales_review,
  sangeettales_subbu,
  madhu_dp_sangeettales,
  suda_dp_sangeettales,
  dj_lash_sangeettales,
  dj_sanjay_sangeettales,
  shruthi_tc_sangeettales,
  sneha_tc_sangeettales,
  preethi_tc_sangeettales,
  sandip_tc_sangeettales,
  niranjani_tc_sangeettales,
  aasha_mc_sangeettales,
  harini_mk_sangeettales,
  preksha_mk_sangeettales,
  kaavya_tc_sangeettales,
  snehasriram_tc_sangeettales,
  ramyaa_tc_sangeettales,
  nikhil_tc_sangeettales,
  vishal_tc_sangeettales,
  rv_vd_sangeettales,
  sabarish_varun,
  sangeettales_07,
  sangeettales_01,
  sangeettales_02,
  sangeettales_03,
  sangeettales_04,
  sangeettales_05,
  sangeettales_06,
  instagramProfile,
  youtubeProfile,
  facebookProfile,
  fb_logo_st,
  insta_logo_st,
  yt_logo_st,
  introvideo,
  wcst,
  thesangeettales_contact_us,
  team_sangeetales,
  footer_web,
  footer_mob,
  toi,
  tnie,

  mg_sangeetales_1,
  mg_sangeetales_2,
  mg_sangeetales_3,
  mg_sangeetales_4,
  mg_sangeetales_5,
  mg_sangeetales_6,
  mg_sangeetales_7,
  mg_sangeetales_8,
  mg_sangeetales_9,
  mg_sangeetales_10,
  mg_sangeetales_11,
  mg_sangeetales_12,
  mg_sangeetales_13,
  mg_sangeetales_14,
  mg_sangeetales_15,
  mg_sangeetales_16,
  mg_sangeetales_17,
  mg_sangeetales_18,
  mg_sangeetales_19,
  mg_sangeetales_20,
  mg_sangeetales_21,
  mg_sangeetales_22,
  mg_sangeetales_23,
  mg_sangeetales_24,
  mg_sangeetales_25,
  mg_sangeetales_26,
  mg_sangeetales_27,
  mg_sangeetales_28,
  mg_sangeetales_29,
  mg_sangeetales_30,
  mg_sangeetales_31,
  mg_sangeetales_32,
  mg_sangeetales_33,
  mg_sangeetales_34,
  mg_sangeetales_35,

  pankaj_review,
  varuna_review,
  sunithra_review,
};
