import React from "react";

import {
  WCST,
  Gallery,
  Header,
  PackagesAndDeals,
  Intro,
  AUS,
  SocialMedia,
  MOT,
  Testimonial
} from "../container";
import { Chat, Gototop } from "../components";
import Articles from "../container/Articles/Articles";

const MainPage = () => {
  return (
    <>
      <Header />
      <Gototop />
      <Chat/>
      <WCST />
      <PackagesAndDeals />
      {/* <Intro /> */}
      {/* <Team /> */}
      {/* <Gallery /> */}
      <SocialMedia />
      <Articles/>
      <AUS/>
      <MOT/>
      <Testimonial/>
    </>
  );
};

export default MainPage;
