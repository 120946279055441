import React from "react";
import "../WCST/WCST.css";
import "./AUS.css";

import { Link } from "react-router-dom";
import { GrGroup } from "react-icons/gr";

const AUS = () => {
  return (
    <>
      <div
        className="app__aboutus black__bg flex__center section__padding"
        id="about"
      >
        {/* <div className="app__aboutus-overlay flex__center">
        <img src={images.sangeettales_bg} alt="G_overlay" />
      </div> */}
        {/* <div className="app__aboutus-content app__wrapper flex__center"> */}
        <div className="app__aboutus-content  flex__center">
          <div
            className="app__aboutus-content_history"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h1 className="headtext__cormorant"> About Us</h1>
            {/* <img src={images.spoon} alt="about_spoon" className="spoon__img" /> */}
            <p className="p__opensans">
              We love bringing joy and togetherness between families and friends
              by celebrating the big days in their lives. We will make{" "}
              <span style={{ color: "var(--color-golden)" }}>YOU</span> dance,
              sing, play and enjoy your heart out! We wish to drive the
              <span style={{ color: "var(--color-golden)" }}>{" "}
                Sangeet culture in a way that fits the South!
              </span>
            </p>
            <p className="p__opensans">
              Our journey started over{" "}
              <span
                style={{ fontSize: "1.5rem", color: "var(--color-golden)" }}
              >
                5
              </span>{" "}
              years ago when we began organising Sangeet events for close
              friends and family. We quickly realised the amount of happiness
              and positivity these events brought among everyone involved, right
              from the youngest of the cousins to the oldest of the
              grandparents. We loved watching the happy jumping jacks within
              every person and relished the golden memories that were created.
            </p>
            <p className="p__opensans">
              We realized how many golden moments in a wedding are being missed
              by the close family members due to last minute problems that
              prevail (caterers, photographers, wedding hall management team,
              decorators, etcetera) A Wedding Coordinator who can act as an
              extended representative of the family, and handle all these issues
              can make a HUGE difference in ensuring a hassle free Wedding!
            </p>
            <p className="p__opensans">
              With Dance & Choreography being one of our specialisations, we
              loved training non-dancers and extended our services to
              Corporates. We strongly believe - All work and no play makes Jack a
              dull boy! In the process, we partnered with very passionate{" "}
              <span style={{ color: "var(--color-golden)" }}>
                Make-up artists, DJs, Choreographers, Dancers, Videographers,
                Anchors
              </span>{" "}
              to provide all relevant services under one umbrella for you all!
            </p>

            <p className="p__opensans">
              Hence was born "The Sangeet Tales", an invitation to a Delightful
              Evening!
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AUS;

/* 
  <div className=" flex__center meet__our__team" >
        <Link
          to="/team#team__head"
          className="custom__button pricing"
          disabled
        >
          <GrGroup/> Meet Our Team
        </Link>
      </div>
      */
