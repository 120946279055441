/* eslint-disable react/jsx-no-undef */
import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Team, Footer } from "./container";
import { Navbar, Loading } from "./components";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import MainPage from "./Pages/MainPage";
import MainGallery from "./Pages/MainGallery/MainGallery";
import VideoBackground from "./components/BgVideo/VideoBackground";

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate an API call with a setTimeout
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <BrowserRouter>
            <Navbar />

            <Routes>
              <Route path="/" element={<MainPage />} />
              <Route path="/home" element={<MainPage />} />
              <Route path="/team" element={<Team />} />
              {/* <Route path="/main-gallery" element={<MainGallery />} /> */}
            </Routes>
            {window.innerWidth >= 768 && (
              <VideoBackground url="https://sangeettales-videos.s3.ap-south-1.amazonaws.com/50+sec+-+Landscape.mp4" />
            )}

            {window.innerWidth < 768 && (
              <VideoBackground url="https://sangeettales-videos.s3.ap-south-1.amazonaws.com/50+sec+-+Vertical+iOS.mov" />
              // <VideoPlayer
              //   className="video"
              //   src="https://sangeettales-videos.s3.ap-south-1.amazonaws.com/ROUGH+1.mp4"
              //   // {images.introvideo}
              //   autoPlay={true}
              //   muted={true}
              // />
            )}
            {/* <Header />
          <AboutUs />
          <PackagesAndDeals /> */}
            {/* <Intro /> */}
            {/* <Team />
          <Gallery /> */}
            <Footer />
          </BrowserRouter>
        </>
      )}
    </div>
  );
};

export default App;
