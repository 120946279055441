import React from "react";
import Card from "react-bootstrap/Card";
import { SubHeading, MenuItem } from "../../components";
import { data, images } from "../../constants";
import "./PackagesAndDeals.css";
import { Link } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";

const PackagesAndDeals = () => (
  <div
    className=" app__specialMenu flex__center section__padding"
    id="packages"
  >
    <div className="app__specialMenu-title">
      {/* <SubHeading title="Discover our Packages & Deals" /> */}
      <h1 className="headtext__cormorant">Our Offerings</h1>
    </div>

    <div
      className="app__specialMenu-menu"
      style={{ display: "flex", justifyContent: "space-around" }}
    >
      <Card
        bg="dark"
        key="dark1"
        text="white"
        style={{ width: "22rem" }}
        className="mb-2"
        border="light"
      >
        <Card.Header
          style={{ backgroundColor: "black" }}
          className="app__specialMenu-menu_heading"
        >
          Wedding Entertainment
        </Card.Header>
        <Card.Body
          style={{
            backgroundColor: "black",
            fontFamily: "var(--font-base)",
            height: "28rem",
          }}
        >
          <Card.Subtitle
            className="mb-2"
            style={{ fontSize: "1.5rem", color: "var(--color-golden)" }}
          >
            Event Planning & Management{" "}
          </Card.Subtitle>
          <span style={{ fontSize: "1rem", color: "var(--color-golden)" }}>
            (Pre-Wedding Party, Sangeet, Bachelorette, Reception)
          </span>
          <br></br>
          <br></br>
          <br></br>

          <ul style={{ fontSize: "1.2rem" }}>
            {data.WeddingEntertainment1.map((deal, index) => (
              <li key={deal + index}>{deal} </li>
            ))}
          </ul>
        </Card.Body>
        <Card.Footer
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "25px",
            backgroundColor: "black",
          }}
        >
          <Link
            to="https://wa.me/919579422697"
            target="_blank"
            rel="noopener noreferrer"
            className="custom__button pricing"
            disabled
          >
            <FaWhatsapp /> WhatsApp Us for pricing
          </Link>
        </Card.Footer>
      </Card>

      <Card
        bg="dark"
        key="dark2"
        text="white"
        style={{ width: "22rem" }}
        border="light"
        className="mb-2"
      >
        <Card.Header
          style={{ backgroundColor: "black" }}
          className="app__specialMenu-menu_heading"
        >
          Dance Choreography
        </Card.Header>
        <Card.Body
          style={{
            fontFamily: "var(--font-base)",
            backgroundColor: "black",
            height: "28rem",
          }}
        >
          <Card.Subtitle
            className="mb-2"
            style={{ fontSize: "1.5rem", color: "var(--color-golden)" }}
          >
            Corporate / Sangeet / Stage show Choreography and training
          </Card.Subtitle>
          <br></br>
          <br></br>
          <br></br>

          <ul style={{ fontSize: "1.2rem" }}>
            {data.WeddingEntertainment2.map((deal, index) => (
              <li key={deal + index}>{deal} </li>
            ))}
          </ul>
        </Card.Body>
        <Card.Footer
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "25px",
            backgroundColor: "black",
          }}
        >
        <Link
            to="https://wa.me/919579422697"
            target="_blank"
            rel="noopener noreferrer"
            className="custom__button pricing"
            disabled
          >
            <FaWhatsapp /> WhatsApp Us for pricing
          </Link>
        </Card.Footer>
      </Card>
      {/* <div className="app__specialMenu-menu_img"> */}
      {/* <img src={images.menu} alt="menu__img" /> */}
      {/* </div> */}
    </div>

    <div
      className="app__specialMenu-menu"
      style={{ display: "flex", justifyContent: "space-around" }}
    >
      <Card
        bg="dark"
        key="dark3"
        text="white"
        style={{ width: "22rem" }}
        className="mb-2"
        border="light"
      >
        <Card.Header
          style={{ backgroundColor: "black" }}
          className="app__specialMenu-menu_heading"
        >
          Variety Entertainment
        </Card.Header>

        <Card.Body
          style={{
            fontFamily: "var(--font-base)",
            height: "25rem",
            backgroundColor: "black",
          }}
        >
          <Card.Subtitle
            className="mb-2"
            style={{ fontSize: "1.5rem", color: "var(--color-golden)" }}
          >
            Stage Shows and Performances
          </Card.Subtitle>
          <br></br>
          <br></br>
          <br></br>

          <ul style={{ fontSize: "1.2rem" }}>
            {data.Variety.map((deal, index) => (
              <li key={deal + index}>{deal} </li>
            ))}
          </ul>
        </Card.Body>
        <Card.Footer
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "25px",
            backgroundColor: "black",
          }}
        >
          <Link
            to="https://wa.me/919579422697"
            target="_blank"
            rel="noopener noreferrer"
            className="custom__button pricing"
            disabled
          >
            <FaWhatsapp /> WhatsApp Us for pricing
          </Link>
        </Card.Footer>
      </Card>

      <Card
        bg="dark"
        key="dark4"
        text="white"
        style={{ width: "22rem" }}
        className="mb-2"
        border="light"
      >
        <Card.Header
          style={{ backgroundColor: "black" }}
          className="app__specialMenu-menu_heading"
        >
          Wedding Coordination
        </Card.Header>

        <Card.Body
          style={{
            fontFamily: "var(--font-base)",
            height: "25rem",
            backgroundColor: "black",
          }}
        >
          <Card.Subtitle
            className="mb-2"
            style={{ fontSize: "1.5rem", color: "var(--color-golden)" }}
          >
            On field management & Coordination
          </Card.Subtitle>
          <br></br>
          <br></br>
          <br></br>

          <ul style={{ fontSize: "1.2rem" }}>
            {data.WeddingCoordination.map((deal, index) => (
              <li key={deal + index}>{deal} </li>
            ))}
          </ul>
        </Card.Body>
        <Card.Footer
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "25px",
            backgroundColor: "black",
          }}
        >
         <Link
            to="https://wa.me/919579422697"
            target="_blank"
            rel="noopener noreferrer"
            className="custom__button pricing"
            disabled
          >
            <FaWhatsapp /> WhatsApp Us for pricing
          </Link>
        </Card.Footer>
      </Card>

      {/* <div className="app__specialMenu-menu_img"> */}
      {/* <img src={images.menu} alt="menu__img" /> */}
      {/* </div> */}
    </div>
  </div>
);

export default PackagesAndDeals;
