import React from "react";

import { SubHeading } from "../../components";
import { images } from "../../constants";
import VideoPlayer from "react-background-video-player";
import "./Header.css";
import { Link } from "react-router-dom";
import "../../components/Navbar/Navbar.css";

const Header = () => {
  return (
    <div className="app__header app__wrapper section__padding" id="home">
      <div className="app__wrapper_info">
        {/* <SubHeading title="Unleashing the Joy of Marriage in Every Moment!" /> */}
        <h1 className="app__header-h1 mobile">The Sangeet Tales</h1>
        <div className="app__wrapper_img banner__style" style={{ zIndex: "1"}}>
          <img src={images.sangeettales_banner_1} alt="header_img" />
        </div>
      </div>

      <div className="app__wrapper_info" style={{ zIndex: "1" }}>
        {/* <SubHeading title="Unleashing the Joy of Marriage in Every Moment!" /> */}
        <h1 className="app__header-h1 web">The Sangeet Tales</h1>
        <p className="p__opensans" style={{ margin: "1rem 0" }}>
          {/* </span>{" "} */}
          Sangeet Tales is a passionate{" "}
          <span style={{ color: "var(--color-golden)" }}>
            Dance & Event Management Company
          </span>
          . We love making your big day a happy and a memorable one for the
          family, friends and of course for the couple!
        </p>
        <p className="p__opensans" style={{ margin: "1rem 0" }}>
          <span style={{ color: "var(--color-golden)" }}>
            Wedding Entertainment
          </span>{" "}
          - We focus on making your Sangeet / Pre-wedding party / Reception
          evening a jovial one through dance, music, games, surprises, toasts,
          dramatic couple entry, nostalgic videos and much more!
        </p>
        <p className="p__opensans" style={{ margin: "1rem 0" }}>
          <span style={{ color: "var(--color-golden)" }}>
            Wedding Coordination
          </span>{" "}
          - Worried about vendor coordination, event supervision or guest
          hospitality? Worry no more! Our team will take care of all the
          wedding hustle & ensure that everyone savours the wedding thoroughly.
        </p>

        <p className="p__opensans" style={{ margin: "1rem 0" }}>
          We offer{" "}
      
          <span style={{ color: "var(--color-golden)" }}>Dance Choreography, Anchoring, Make Up, DJ, Live Stage Performances, Music, Videography, Coordination
          </span> and much more!
            
             We do <span style={{ color: "var(--color-golden)" }}>Corporate Shows </span> as well!
             We take care of your event needs    <span style={{ color: "var(--color-golden)" }}>
            {" "} END TO END.
          </span>{" "}
          We do in-person <h6 className="p__opensans" style={{display:'inline'}}>Sangeet Choreography in Chennai </h6> and have also partnered with trainers outside Chennai & India to cater to all your needs from anywhere!
    </p>
    <p className="p__opensans" style={{ margin: "0.5rem 0" }}>
          Meet our young, energetic and talented{" "}
          <Link
            to="/home#mot"
            className="team__css"
            // onClick={() => setToggleMenu(false)}
          >
            TEAM
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Header;
