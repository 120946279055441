/* eslint-disable react/jsx-no-undef */
import React from "react";
import {  SubHeading } from "../../components";
import "./SocialMedia.css";
import "../WCST/WCST.css"
import "../MOT/MOT.css"
import { images } from "../../constants";
import { Link } from "react-router-dom";

const SocialMedia = () => {
  return (
    <div className= "app__socialMedia section__padding" id="social_media">
      <div className="app__socialMedia-title">
        <h1 className="headtext__cormorant">Social Media</h1>
        <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <p
              className="p__opensans glow__text"
              style={{
                color: "white",
                fontSize: "15px",
                lineHeight: "2",
              }}
            >
             Check us on Instagram, Facebook and Youtube!!!
            </p>
            </div>
      </div>

      <div className="app__social_media__container">
        {/* <img src={images.instagramProfile} className="social__media__card" />
       
        <img src={images.youtubeProfile} className="social__media__card" />
        <img src={images.facebookProfile} className="social__media__card" /> */}
        {/* <FlipCards/>
         */}

        <div className="app__social_wrapper_img " style={{ zIndex: "1" }}>
          <Link
            to="https://www.instagram.com/thesangeettales/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {/* <Card border="dark" className="sm__style mb-2 ">
            <Card.Img variant="top" src={images.insta_logo_st} />
          </Card> */}
            <img className="sm__style" src={images.insta_logo_st} />
          </Link>
          <Link
            to="https://youtube.com/@TheSangeetTales"
            target="_blank"
            rel="noopener noreferrer"
          >
            {/* <Card border="dark" className="sm__style mb-2 ">
            <Card.Img variant="top" src={images.insta_logo_st} />
          </Card> */}
            <img className="sm__style" src={images.yt_logo_st} />
          </Link>
          <Link
            to="https://www.facebook.com/TheSangeetTales"
            target="_blank"
            rel="noopener noreferrer"
          >
            {/* <Card border="dark" className="sm__style mb-2 ">
            <Card.Img variant="top" src={images.insta_logo_st} />
          </Card> */}
            <img className="sm__style" src={images.fb_logo_st} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SocialMedia;
