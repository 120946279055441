import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { images } from "../../constants";
import "./WCST.css";

const WCST = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const target = document.querySelector(location.hash);
      target.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  return (
    <div
      className="app__aboutus black__bg flex__center section__padding"
      id="wcst"
    >
      {/* <div className="app__aboutus-overlay flex__center">
        <img src={images.sangeettales_bg} alt="G_overlay" />
      </div> */}

      {/* <div className="app__aboutus-content app__wrapper flex__center"> */}
      <div className="app__aboutus-content app__wrapper flex__center">
        {/* <div className="app__aboutus-content_history">
          <h1 className="headtext__cormorant">About Us</h1>
          <img src={images.spoon} alt="about_spoon" className="spoon__img" />
          <p className="p__opensans">
            We are a team of passionate individuals who love bringing joy and
            togetherness to families and friends through the celebration of
            Wedding events.
          </p>
          <p className="p__opensans">
            Our journey started over{" "}
            <span style={{ fontSize: "1.5rem", color: "var(--color-golden)" }}>
              10
            </span>{" "}
            years ago when we began organising Sangeet events for close friends
            and family. We quickly realised how much happiness and positivity
            these events brought to everyone involved, from the youngest to the
            oldest family members. We loved seeing the happy jumping jacks
            within each person and the golden memories that were created.
          </p>
          <p className="p__opensans">
            Recently, we realized that close family members miss out on a lot of
            important wedding moments due to small small problems / issues
            created by vendors (caterers, photographers, wedding hall management
            team, decorators) etc! A wedding coordinator who can act as an
            extended representative of the family , and handle all these issues
            can make a HUGE difference to a hustle free wedding!!
          </p>
          <p className="p__opensans">
            Hence was born "The Sangeet Tales", an invitation to a memorable
            wedding!
          </p>
     
        </div> */}

        {/* <div className="app__aboutus-content_knife flex__center">
          <img src={images.sangeettales_splitimage} alt="sangeettales_splitimage" />
        </div> */}

        <div className="app__aboutus-content_history" style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
          <h1 className="headtext__cormorant">Why Choose Sangeet Tales?</h1>
          {/* <img
            src={images.spoon}
            alt="about_sangeet_tales"
            className="spoon__img"
          /> */}
          <div className="app__wrapper_img">
            <img src={images.wcst} alt="wcst_sangeettales" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WCST;
