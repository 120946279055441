import React from "react";
import "./Loading.css";

const Loading = () => (
  <>
    <div className="loader">
      <div className="cssload-loader">The Sangeet Tales</div>
    </div>
  </>
);

export default Loading;
