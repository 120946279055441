import React from "react";
import "./Team.css";

const TeamMember = ({ image, name, title, mywords, shortName }) => {
  return (
    <div className="app__bg app__wrapper section__padding" id="team">
      <div className="app__wrapper_img app__wrapper_img-reverse image__front">
        <img src={image} alt="team_image" />
      </div>
      <div className="app__wrapper_info">
        {/* <SubHeading title="team's word" /> */}
        {/* <h1 className="headtext__cormorant">What we believe in</h1> */}

        <div className="app__team-content">
          {/* <div className="app__team-content_quote">
          <img src={images.quote} alt="quote_image" />
          <p className="p__opensans">
          Sangeets: where culture meets cool, hearts dance to tradition, and a fusion of fun and festivity ignites!
          </p>
        </div><br/>
         */}
          <p className="p__opensans">
            {" "}
            <span style={{ fontSize: "1.5rem", color: "var(--color-golden)" }}>
              {shortName}
            </span>{" "}
            {mywords}
          </p>
        </div>

        <div className="app__team-sign">
          <p>{name}</p>
          <p className="p__opensans" style={{ textAlign: "center" }}>
            {title}
          </p>
          {/* <img src={images.sign} alt="sign_image" /> */}
        </div>
      </div>
    </div>
  );
};

export default TeamMember;
