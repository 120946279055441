/* eslint-disable react/jsx-no-undef */
import React from "react";
import {  SubHeading } from "../../components";
import "./Articles.css";
import "../WCST/WCST.css"
import "../MOT/MOT.css"
import { images } from "../../constants";
import { Link } from "react-router-dom";

const Articles = () => {
  return (
    <div className= "app__article section__padding" id="articles">
      <div className="app__article-title">
        <h1 className="headtext__cormorant">Articles</h1>
        <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <p
              className="p__opensans glow__text"
              style={{
                color: "white",
                fontSize: "15px",
                lineHeight: "2",
              }}
            >
           Featured on Times Of India and The New Indian Express
            </p>
            </div>
      </div>

      <div className="app__article__container">
        {/* <img src={images.instagramProfile} className="social__media__card" />
       
        <img src={images.youtubeProfile} className="social__media__card" />
        <img src={images.facebookProfile} className="social__media__card" /> */}
        {/* <FlipCards/>
         */}

        <div className="app__social_wrapper_img " style={{ zIndex: "1" }}>
          <Link
            to="https://timesofindia.indiatimes.com/entertainment/kannada/music/insta-reel-hits-take-over-wedding-playlists/articleshow/101401484.cms"
            target="_blank"
            rel="noopener noreferrer"
          >
            {/* <Card border="dark" className="sm__style mb-2 ">
            <Card.Img variant="top" src={images.insta_logo_st} />
          </Card> */}
            <img className="sm__style" src={images.toi} />
          </Link>
          {/* <Link
            to="https://youtube.com/@TheSangeetTales"
            target="_blank"
            rel="noopener noreferrer"
          >
            
            <img className="sm__style" src={images.yt_logo_st} />
          </Link> */}
          <Link
            to="https://www.newindianexpress.com/cities/chennai/2023/jul/18/sangeet-party-in-a-kalyanam-2595777.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            {/* <Card border="dark" className="sm__style mb-2 ">
            <Card.Img variant="top" src={images.insta_logo_st} />
          </Card> */}
            <img className="sm__style" src={images.tnie} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Articles;
