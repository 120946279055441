import React, { useState, useEffect } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./Chat.css";

const Chat = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > 200) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Link
      className={`chat-button ${isVisible ? "is-visible" : ""}`}
      to="https://wa.me/919579422697"
            target="_blank"
            rel="noopener noreferrer"
            
            disabled
    //   onClick={scrollToTop}
    > 
      <FaWhatsapp />
    </Link>
  );
};

export default Chat;
