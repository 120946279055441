import React from "react";
import "./Testimonial.css";
import { SubHeading } from "../../components";
import { Card, Col, Row } from "react-bootstrap";
import { images } from "../../constants";
import { Link } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";

const Testimonial = () => {
  return (
    <div className="app__testimonial section__padding" id="testimonial">
      <div className="app__testimonial-title">
        <h1 className="headtext__cormorant">Testimonials</h1>
        <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <p
              className="p__opensans glow__text"
              style={{
                color: "white",
                fontSize: "15px",
                lineHeight: "2",
              }}
            >
              Here is what our Valued Clients say about us. Hover on the cards to read their Reviews!!
            </p>
            </div>
      </div>

      <div style={{ zIndex: "1" }}>
        <div className="app__testimonial__container">
          <Row
            xs={1}
            md={3}
            className="g-4"
            style={{
              zIndex: "1",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Col
              key={1}
              style={{
                zIndex: "1",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="cards">
                <div className="face front-face">
                  <img src={images.pankaj_review} alt="" className="profile" />
                  <div className="pt-3 text-uppercase name">Pankaj Mehra</div>
                  <div className="designation">Groom</div>
                </div>
                <div className="face back-face">
                  <span className="fas fa-quote-left"></span>
                  <div className="testimonial">
                    Thank you so much for all your efforts & keeping everyone
                    involved throughout the event. You have amazing qualities to
                    keep up the energy level high. You covered all Family &
                    friends. ❤️🥳❤️. I wish you all the very best and greater
                    success for your future. Thank you 🥰. You made it memorable
                    forever !!
                  </div>
                  <span className="fas fa-quote-right"></span>
                </div>
                {/* </Card.Body> */}
              </div>
            </Col>
            <Col
              key={2}
              style={{
                zIndex: "1",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="cards">
                <div className="face front-face">
                  <img
                    src={images.sunithra_review}
                    alt=""
                    className="profile"
                  />
                  <div className="pt-3 text-uppercase name">Sunithra</div>
                  <div className="designation">Bride's Sister</div>
                </div>
                <div className="face back-face">
                  <span className="fas fa-quote-left"></span>
                  <div className="testimonial">
                    The event was a success! Big thanks to you for the energy
                    you brought to the event and diligent coordination,
                    @sangeettales ! Appreciations to Subbu too for the amazing
                    coord! Special thanks for putting up with our tight
                    schedules and delayed responses… Guests and relatives
                    enjoyed the event thoroughly! Kamal and Anshu were floored
                    with the surprises! Glad we made it happen!
                  </div>
                  <span className="fas fa-quote-right"></span>
                </div>
                {/* </Card.Body> */}
              </div>
            </Col>
            <Col
              key={3}
              style={{
                zIndex: "1",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="cards">
                <div className="face front-face">
                  <img src={images.varuna_review} alt="" className="profile" />
                  <div className="pt-3 text-uppercase name">
                    Varuna Suresh kumar
                  </div>
                  <div className="designation">Bride's Sister</div>
                </div>
                <div className="face back-face">
                  <span className="fas fa-quote-left"></span>
                  <div className="testimonial">
                    Extremely talented dancers. They have the power to make
                    anyone come forward and rock the stage. I would definitely
                    recommend them for any kind of celebration event. Their
                    presence alone will spark the place ❤️ I wish you all the
                    best 💐🤗 You guys rock!
                  </div>
                  <span className="fas fa-quote-right"></span>
                </div>
                {/* </Card.Body> */}
              </div>
            </Col>
            <Col
              key={4}
              style={{
                zIndex: "1",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            ></Col>
            <Col
              key={5}
              style={{
                zIndex: "1",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{marginTop:'30px'}}>
                <Link
                  to="https://g.co/kgs/5USfii"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="custom__button p__opensans pricing" style={{color:'black', boxShadow:" 0 0 20px #bbb"}}
                // className="btn btn-3"
            
                >
                 <FcGoogle style={{fontSize:'20px'}}/>  View More  Reviews
                </Link>
              </div>
            </Col>
            <Col
              key={6}
              style={{
                zIndex: "1",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            ></Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
