import React from "react";
import { FiFacebook, FiTwitter, FiInstagram, FiYoutube } from "react-icons/fi";
import { FaWhatsapp , FaMapMarkerAlt} from "react-icons/fa";

import { FooterOverlay, ContactUs } from "../../components";
import { images } from "../../constants";
import "./Footer.css";

const Footer = () => (
  <div className="app__footer section__padding">
    {/* <FooterOverlay /> */}
    <ContactUs />
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <div className="app__specialMenu-title" id="contact">
      <h1 className="headtext__cormorant">Contact Us</h1>
      {/* <img src={images.spoon} alt="spoon_image" className="spoon__img" /> */}
    </div>
    <div>
      {window.innerWidth >= 768 && (
        <img src={images.footer_web} className="contact__us"></img>
      )}
      {window.innerWidth < 768 && (
        <img src={images.footer_mob} className="contact__us"></img>
      )}
    </div>
    {/* <div className="app__footer-links">
      <div className="app__footer-links_contact">
        <p className="p__opensans">The Sangeet Tales</p>
        <p className="p__opensans">Wedding Entertainment & Management Company</p>
        <p className="p__opensans"> Annamalai Colony, Saligramam, Chennai, Tamil Nadu 600094</p>
        <p className="p__opensans">+919579422697</p>
        <p className="p__opensans">+91 9551231002</p>
        <p className="p__opensans">enquiry@sangeettales.com</p>
      </div>

      <div className="app__footer-links_logo">
        <img src={images.sangeetTales} alt="footer_logo" />
        
      </div>
    </div> */}

    {/* <div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1496.5212113738407!2d80.19965058969952!3d13.053612814262529!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x22ab4da437b71fa3%3A0x1a18206e29ef08cc!2sThe%20Sangeet%20Tales!5e0!3m2!1sen!2sin!4v1686658077958!5m2!1sen!2sin"
        className="map"
        allowFullScreen={true}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div> */}
    <div className="footer__copyright ">
      <div
        className="app__footer-links_icons"
        style={{
          marginLeft: 50,
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <a href="https://wa.me/919579422697" target="_blank" rel="noreferrer">
          <FaWhatsapp />
        </a>
        <a
          href="https://youtube.com/@TheSangeetTales"
          target="_blank"
          rel="noreferrer"
        >
          <FiYoutube />
        </a>
        <a
          href="https://www.instagram.com/thesangeettales/"
          target="_blank"
          rel="noreferrer"
        >
          <FiInstagram />
        </a>
        <a
          href="https://www.facebook.com/TheSangeetTales"
          target="_blank"
          rel="noreferrer"
        >
          <FiFacebook />
        </a>
        {/* <FiTwitter /> */}
        <a
          href="https://goo.gl/maps/hpxgqSvDPu9iPce29"
          target="_blank"
          rel="noreferrer"
        >
           <FaMapMarkerAlt/>
        </a>
      
      </div>

      <p className="p__opensans" style={{ marginTop: 55, marginLeft: 50 }}>
        {new Date().getFullYear()} The Sangeet Tales. All Rights reserved.
      </p>
    </div>
  </div>
);

export default Footer;
