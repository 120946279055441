import React from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdClose } from "react-icons/md";
import images from "../../constants/images";
import "./Navbar.css";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = React.useState(false);
  return (
    <nav
      className="app__navbar"
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
      }}
    >
      <Link to="/home" className="app__navbar-logo">
        <img src={images.sangeetTales} alt="app__logo" />
      </Link>
      <ul className="app__navbar-links">
        <li className="p__opensans">
          <Link
            to="/home"
            className="navbar__Item"
            onClick={() => setToggleMenu(false)}
          >
            Home
          </Link>
        </li>
        <li className="p__opensans">
          <Link
            to="/home#about"
            className="navbar__Item"
            onClick={() => {
              setToggleMenu(false);
            }}
          >
            About
          </Link>
        </li>
        <li className="p__opensans">
          <Link
            to="/home#packages"
            className="navbar__Item"
            onClick={() => setToggleMenu(false)}
          >
            Packages
          </Link>
        </li>
        <li className="p__opensans">
          <Link
            to="/home#social_media"
            className="navbar__Item"
            onClick={() => setToggleMenu(false)}
          >
            Social Media
          </Link>
        </li>
        <li className="p__opensans">
          <Link
            to="/home#articles"
            className="navbar__Item"
            onClick={() => setToggleMenu(false)}
          >
            Articles
          </Link>
        </li>
        <li className="p__opensans">
          {/* <a href="#team">Team</a> */}
          <Link
            to="/home#mot"
            className="navbar__Item"
            onClick={() => setToggleMenu(false)}
          >
            Team
          </Link>
        </li>
        <li className="p__opensans">
          {/* <a href="#team">Team</a> */}
          <Link
            to="/home#testimonial"
            className="navbar__Item"
            onClick={() => setToggleMenu(false)}
          >
            Testimonials
          </Link>
        </li>

        <li className="p__opensans">
          {/* <a href="#team">Team</a> */}
          <Link
            to="/home#wcst"
            className="navbar__Item"
            onClick={() => setToggleMenu(false)}
          >
            Why Us?
          </Link>
        </li>
        {/* <li className="p__opensans">
          <Link
            to="/main-gallery"
            className="navbar__Item"
            onClick={() => setToggleMenu(false)}
          >
            Gallery
          </Link>
        </li> */}
        <li className="p__opensans">
          <Link
            to="/home#contact"
            className="navbar__Item"
            onClick={() => setToggleMenu(false)}
          >
            Contact
          </Link>
        </li>
      </ul>
      <div className="app__navbar-smallscreen">
        <GiHamburgerMenu
          color="#fff"
          fontSize={27}
          onClick={() => setToggleMenu(true)}
        />
        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
            <MdClose
              fontSize={27}
              className="overlay__close"
              onClick={() => setToggleMenu(false)}
            />
            <ul className="app__navbar-smallscreen_links">
              {/* <li>
                <Link
                  to="/home"
                  className="navbar__Item"
                  onClick={() => setToggleMenu(false)}
                >
                  Home
                </Link>
              </li> */}
              <li>
                <Link
                  to="/home#about"
                  className="navbar__Item"
                  onClick={() => {
                    setToggleMenu(false);
                  }}
                >
                  About
                </Link>
              </li>
              <li>
                <Link
                  to="/home#packages"
                  className="navbar__Item"
                  onClick={() => setToggleMenu(false)}
                >
                  Packages
                </Link>
              </li>
              <li>
                <Link
                  to="/home#social_media"
                  className="navbar__Item"
                  onClick={() => setToggleMenu(false)}
                >
                  Social Media
                </Link>
              </li>

              <li>
                <Link
                  to="/home#articles"
                  className="navbar__Item"
                  onClick={() => setToggleMenu(false)}
                >
                  Articles
                </Link>
              </li>
              
              <li>
                <Link to="/home#mot" onClick={() => setToggleMenu(false)}>
                  Team
                </Link>
              </li>

              <li>
                <Link  to="/home#testimonial" onClick={() => setToggleMenu(false)}>
                Testimonials
                </Link>
              </li>


              <li>
                <Link to="/home#wcst" onClick={() => setToggleMenu(false)}>
                  Why Us?
                </Link>
              </li>
              {/* <li>
                <Link
                  to="/main-gallery"
                  className="navbar__Item"
                  onClick={() => setToggleMenu(false)}
                >
                  Gallery
                </Link>
              </li> */}
              <li>
                <a href="#contact" onClick={() => setToggleMenu(false)}>
                  Contact
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
