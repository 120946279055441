import React from "react";
import { images } from "../../constants";
import "./Team.css";

import TeamMember from "./TeamMember";
import { Chat, Gototop } from "../../components";
import { useEffect } from "react";
import { useLocation, Link } from "react-router-dom";

const Team = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const target = document.querySelector(location.hash);
      target.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);
  return (
    <>
      <Gototop />
      <Chat />
      <div className=" app__bg" id="team__head">
        <div className="app__specialMenu-title ">
          <h1 className="headtext__cormorant">The Team</h1>

          {/* <img src={images.spoon} alt="spoon_image" className="spoon__img" /> */}
        </div>
        {/* <div className="image__front">
          <img
            src={images.team_sangeetales}
            alt="team_sangeetales"
            className="team_img "
          />
        </div> */}
        <div className="team__links container">
          <Link
            to="/team#founders"
            style={{ zIndex: "1", width: "150px", justifyContent: "center" }}
            className="btn btn-1 pricing flex__center"
            disabled
          >
            Founders
          </Link>

          <Link
            to="/team#dance_master"
            style={{ zIndex: "1", width: "150px", justifyContent: "center" }}
            className="btn btn-1 pricing flex__center"
            disabled
          >
            Dance Master
          </Link>
          <Link
            to="/team#trainers"
            style={{ zIndex: "1", width: "150px", justifyContent: "center" }}
            className="btn btn-1 pricing flex__center"
            disabled
          >
            Trainers & Choreographers
          </Link>
        </div>
        <div className="team__links container">
          <Link
            to="/team#anchors"
            style={{ zIndex: "1", width: "150px", justifyContent: "center" }}
            className="btn btn-3 pricing flex__center"
            disabled
          >
            Anchors
          </Link>
          <Link
            to="/team#makeup_artists"
            style={{ zIndex: "1", width: "150px", justifyContent: "center" }}
            className="btn btn-3 pricing flex__center"
            disabled
          >
            MakeUp Artists
          </Link>
          <Link
            to="/team#dj_partners"
            style={{ zIndex: "1", width: "150px", justifyContent: "center" }}
            className="btn btn-3 pricing flex__center"
            disabled
          >
            DJ Partners
          </Link>
        </div>
        <div className="team__links container">
          <Link
            to="/team#video_partner"
            style={{ zIndex: "4", width: "150px", justifyContent: "center" }}
            className="btn btn-2 pricing flex__center"
            disabled
          >
            Video Partner
          </Link>
          <Link
            to="/team#digital_partners"
            style={{ zIndex: "1", width: "150px", justifyContent: "center" }}
            className="btn btn-2 pricing flex__center"
            disabled
          >
            Digital Partners
          </Link>

          <Link
            to="/team#weather_boys"
            style={{ zIndex: "1", width: "150px", justifyContent: "center" }}
            className="btn btn-2 pricing flex__center"
            disabled
          >
            All Weather Boys
          </Link>
        </div>

        <div className="app__specialMenu-title" style={{ marginTop: "5%" }}>
          <h1 className="headtext__cormorant" id="founders">
            Meet Our Founders
          </h1>

          {/* <img src={images.spoon} alt="spoon_image" className="spoon__img" /> */}
        </div>
        <TeamMember
          image={images.sangeettales_varsha}
          name={"Varsha Jayalakshmi"}
          mywords={` is the most energetic and fun loving person who you can ever come across!
          She is a Chartered Accountant by profession. She has been dancing and anchoring for over 16 years now.
          Varsha learnt Bharatanatyam under Dr Sheela Unnikrishnan and was part of Sri Devi Nrithyalaya for over 13 years.
          She has learnt and explored other styles of dance through her tenure as a partner in TTT Dance Team. She is also an athlete, so have no doubts as to why she is such a jovial person.
          And trust us, she will pass on all the energetic vibes onto each one of you!`}
          title={"Founder & Lead - Wedding Entertainment | The Sangeet Tales"}
          shortName={`Varsha`}
        />
        <TeamMember
          image={images.sangeettales_subbu}
          name={"Subbulakshmi Sivakumar"}
          mywords={` is bright minded and dedicated, with passion for art and design. Having been associated with the Chinmaya Mission since childhood,
          she has helped organise and coordinate for numerous events at Chinmaya Yuva Kendra. She is always up for a challenge and manages to complete every last-minute task with ease. Her love for art has encouraged her to constantly explore and learn. She is currently studying design. 
          As far as planning a wedding is concerned, Subbulakshmi is the go-to person for everything technical, logistical or creative!`}
          title={"CoFounder & Lead - Wedding Coordination | The Sangeet Tales"}
          shortName={`Subbulakshmi`}
        />

        <div className="app__specialMenu-title">
          <h1 className="headtext__cormorant" id="dance_master">
            Meet our Dance Master
          </h1>
          {/* <img src={images.spoon} alt="spoon_image" className="spoon__img" /> */}
        </div>

        <TeamMember
          image={images.sandip_tc_sangeettales}
          name={"Santhip Devaki"}
          mywords={`
           is a Choreographer and a Dance Master! With a rich experience of 7 years in the dance industry, Santhip has trained over 100 people till now! He specialises in Stage Shows, Varamala Dance, Lipdup and Samba! Santhip is a down-to-earth and a very humble person! He is a fun loving person and does magical on-the-spot choreographies that will impress anybody easily.
          We can enlighten your wedding evening with a variety entertainment program lead by Santhip and his crew!`}
          title={"Dance Master | The Sangeet Tales"}
          shortName={`Santhip Devaki `}
        />

        <div className="app__specialMenu-title" id="trainers">
          <h1 className="headtext__cormorant">Trainers & Choreographers</h1>
          {/* <img src={images.spoon} alt="spoon_image" className="spoon__img" /> */}
        </div>

        <TeamMember
          image={images.niranjani_tc_sangeettales}
          name={"Niranjani Ramasubramaniyan"}
          mywords={` is the CEO and Founder of FrayLabel. Owning a business is quite tedious yet exciting. With having to run around and get a lot of things done,
           it becomes hard for her to take a mental breather. Dancing and training for The Sangeet Tales just seems the right way to relax. She loves the dynamic team and the way the clients respond.
           Niranjani has always been a bubble of energy and it seems just right to be working with her.`}
          title={"Trainer & Choreographer | The Sangeet Tales"}
          shortName={`Niranjani`}
        />

        <TeamMember
          image={images.kaavya_tc_sangeettales}
          name={"Kavya Bhaskar"}
          mywords={` is a Dance Influencer and a Choreographer with a deep love for movements and self-expression. She has worked as a Choreographer for various theatrical ads and short films and has danced on various platforms including Bigg Boss Malayalam.
           She has won various awards for her dance content on social media. While Kavya, an MBA graduate works as a Financial Consultant at a Big 4 Company during the day, she always finds time to pursue her passion for dance. She has trained herself in various styles and loves exploring the same.
           She has been choreographing for Sangeet for the last 3 years and is now absolutely delighted to be a part of The Sangeet Tales.`}
          title={"Trainer & Choreographer | The Sangeet Tales"}
          shortName={`Kavya Bhaskar`}
        />
        <TeamMember
          image={images.ramyaa_tc_sangeettales}
          name={"Ramyaa Rathna Kumar"}
          mywords={` a passionate engineer, has seamlessly integrated her love for dance into her life. From her childhood stage performances to her ventures into Zumba and dance choreography, she's always found ways to follow her interests. 
          She strongly believes in the ability of dance to bring pure happiness to people, and she's eagerly looking forward to sharing this joy with families through Sangeet Tales.`}
          title={"Trainer & Choreographer | The Sangeet Tales"}
          shortName={`Ramyaa `}
        />
         <TeamMember
          image={images.snehasriram_tc_sangeettales}
          name={"Sneha Sriram"}
          mywords={` , A psychologist by profession and dancer by passion, it is my immense pleasure to be a part of Sangeet tales. I have been training as a dancer since I was 6. Started off with bharatanatyam and took a short detour into the western styles.
          I have been a dance trainer from when I was 18, trained and taught different styles like hip hop, contemporary, jazz, and Bollywood. I was a part of a musical at expo 2020 Dubai, called the why musical which was directed by Shekhar Kapur and composed by AR Rahman. I represented India in that performance and also assisted in the choreography. Ever since childhood, my favorite place to dance was at weddings.
          Now choreographing for Sangeet’s and seeing and experiencing the joy of families getting together and dancing makes me extremely joyful. I am extremely excited to be part of Sangeet tales and can’t wait to spread this joy with everyone `}
          title={"Trainer & Choreographer | The Sangeet Tales"}
          shortName={`Sneha Sriram `}
        />

        <TeamMember
          image={images.vishal_tc_sangeettales}
          name={"Vishal Balakrishnan"}
          mywords={` is a professional dancer! He is young, energetic and a very passionate human being! Vishal is a part of one of the leading dance institutions in Chennai! You will love to see how he seemlessly makes families dance, come up with spontaeneus ideas on event evenings and do his best to make your day a memorable one!`}
          title={"Trainer & Choreographer | The Sangeet Tales"}
          shortName={`Vishal`}
        />

        <TeamMember
          image={images.preethi_tc_sangeettales}
          name={"Preethi N"}
          mywords={`is a fashion designer by profession. She has worked in multiple films and has styled a lot of actresses across the country! Preethi has also worked as a stylist for multiple commercials.
           As a bonus, Preethi is also a trained Hip-Hop dancer! She is a joy to work with and is loved by all!`}
          title={"Trainer & Choreographer | The Sangeet Tales"}
          shortName={`Preethi`}
        />

        <TeamMember
          image={images.nikhil_tc_sangeettales}
          name={"Nikhil Charu"}
          mywords={`The satisfaction that someone is able to enjoy their big day is what pushes Nikhil to go the extra mile. Being with The Sangeet Tales has given him the freedom to make people smile even on an extremely busy day. Given an opportunity, he’d be most willing to do that as well.`}
          title={"Trainer & Choreographer | The Sangeet Tales"}
          shortName={``}
        />

        <TeamMember
          image={images.shruthi_tc_sangeettales}
          name={"Shruthi Rathnakumar"}
          mywords={` is a Chartered Accountant by profession. She currently lives in the United Kingdom.
          Her passion for dance is a blessing for Sangeet Tales!
          She will be helping our families in different geographies learn happy sequences online!
          Shruthi is a hustler and can multi-task efficiently. Shruthi is a trained classical dancer.
          She loves dancing and has been dancing since her school days.
          She enjoys dancing different styles and moreover loves to choreograph and teach Sangeet sequences for fun loving families.
          She is a joy to work with and is loved by all!`}
          title={"Trainer & Choreographer | The Sangeet Tales"}
          shortName={`Shruthi`}
        />

        <TeamMember
          image={images.sneha_tc_sangeettales}
          name={"Snehaa Seshadrinath"}
          mywords={` is a full time HR professional. She is highly passionate towards all performing arts and is now focussing on theatre scenes.
           Being a big time groove head, one can find her often freestyling to Bollywood Fusion & Tamil Folk. She is a fire cracker on stage!
           She brings so much of life and energy on stage! Being a theatre artist, Snehaa can amaze you with her acting, singing and dancing skills! Upon all, she is a down to earth and a very jovial person to be with!`}
          title={"Trainer & Choreographer | The Sangeet Tales"}
          shortName={`Snehaa`}
        />

        <div className="app__specialMenu-title">
          <h1 className="headtext__cormorant" id="anchors">
            Meet our Anchors
          </h1>
          {/* <img src={images.spoon} alt="spoon_image" className="spoon__img" /> */}
        </div>
        <TeamMember
          image={images.aasha_mc_sangeettales}
          name={"Ashameera Aiyappan"}
          mywords={` is one of the multi-talented women of The Sangeet Tales! She is a beautiful singer, a freelance journalist, a writer and an anchor. She is a popular face and has appeared in the Columns / Interviews / Talk shows of The Indian Express, FirstPost, Puthiya Thalaimurai, Kalaignar TV, Behindwoods, Galatta and many more media channels! Above all, Asha Meera is a down to earth person and a wonderful event host who is loved by all!`}
          title={"Anchor | The Sangeet Tales"}
          shortName={`Ashameera`}
        />

        <div className="app__specialMenu-title">
          <h1 className="headtext__cormorant" id="makeup_artists">
            Meet our Make up artists
          </h1>
          {/* <img src={images.spoon} alt="spoon_image" className="spoon__img" /> */}
        </div>
        <TeamMember
          image={images.harini_mk_sangeettales}
          name={"Harini Kamal"}
          mywords={` is a self-taught make-up artist who runs her own make up company "Mithra Makeover Artistry". Mithra Makeover Artistry strongly believes that everybody is beautiful and we are just there to enhance it little. Mithra Makeover Artistry provides all-round makeover services, including Bridal and Non Bridal. Our Bridal services include HD makeup, Bridal Hairdo by professional Hairstylists and Saree / Lehenga draping. We help our brides with the overall styling tips and ideas for their big day. Each and every client of ours is so very important to us because they chose us to be a part of their big day.`}
          title={"Make up artist and on field partner | The Sangeet Tales"}
          shortName={`Harini`}
        />

        <TeamMember
          image={images.preksha_mk_sangeettales}
          name={"Preksha Chordia"}
          mywords={` initially started Wedding Styling by catering her services to her cousins and extended family members. She finds joy in intricate details and creative opportunities. Whether she’s planning the outfits or discussing makeup looks, there’s nothing she loves more than putting her own spin on things and creating something fresh. She is known for her impeccable taste and unique style. She started her career in 2016 as an Assistant Costume Designer in Kollywood and Tollywood. And now she is an independent Stylist and a Make-Up Artist. With her Unparalleled eye for fashion, style and makeup, she has the knack to create a look which is stylish and appealing. Clients who choose to work with Preksha appreciate her candour, caring personality and sincerity. She takes great satisfaction in seeing everyone happy and is always grateful for the opportunity to make this happen!`}
          title={"Make up artist & Wedding Stylist | The Sangeet Tales"}
          shortName={`Preksha`}
        />

        <div className="app__specialMenu-title" id="dj_partners">
          <h1 className="headtext__cormorant">DJ Partners</h1>
          {/* <img src={images.spoon} alt="spoon_image" className="spoon__img" /> */}
        </div>

        <TeamMember
          image={images.dj_lash_sangeettales}
          name={"DJ Lash"}
          mywords={` is a versatile DJ in the business of Entertainment and Event Management Industry with amazing crowd controlling skills . The major trump card of DJ Lash is that he covers many languages to satisfy the client's expectations. With over 8+ years of experience in the field, he knows the clients expectations and he fulfils them. He has performed in more than 1000+ successful events in and around Chennai and the rest of India as well!`}
          title={"DJ partner | The Sangeet Tales"}
          shortName={`DJ Lash`}
        />

        <TeamMember
          image={images.dj_sanjay_sangeettales}
          name={"DJ Sanjay"}
          mywords={` started his passion towards DJ-ing in 2014 and became a certified Master DJ. He has an experience of 600+ domestic and international concerts. If you are looking for a DJ who can make your private Parties, Sangeet, Wedding Events, Pool Parties or Corporate Events happening, look no further!`}
          title={"DJ partner | The Sangeet Tales"}
          shortName={`DJ NJ`}
        />

        <div className="app__specialMenu-title" id="video_partner">
          <h1 className="headtext__cormorant">Video Partner</h1>
          {/* <img src={images.spoon} alt="spoon_image" className="spoon__img" /> */}
        </div>

        <TeamMember
          image={images.rv_vd_sangeettales}
          name={"Vignesh R"}
          mywords={` is the man behind all the nostalgic memory videos that we compile and present as a warm surprise to the couples during their big day! He is a very thoughtful videographer and editor, who can knit together your precious moments into a beautiful story!
          He has a keen eye for detail, excellent communication skills and a strong understanding of video editing software and techniques. He has worked on a variety of projects including short films, promotional B-Roll cuts, music videos, documentaries and corporate videos.
           He is passionate about his work and always strives to deliver high-quality results that exceeds the client's expectations.`}
          title={"Video partner| The Sangeet Tales"}
          shortName={`Vignesh`}
        />
        <div className="app__specialMenu-title">
          <h1 className="headtext__cormorant" id="digital_partners">
            Digital Partners
          </h1>
          {/* <img src={images.spoon} alt="spoon_image" className="spoon__img" /> */}
        </div>
        <TeamMember
          image={images.madhu_dp_sangeettales}
          name={"Madhurika Tetali"}
          mywords={` ,the lady who designed our logo has always been facinated about working as a Wedding Event Manager. When Sangeet Tales was formed, she found it as a path to channel her newfound love for designing. She is super excited to collaborate with the team and make any event a sensation!
          Madhu can design your Welcome Boards , Invites, Cards, Wedding bags that will add a lot of aesthetics to the wedding vibe!`}
          title={"Digital partner | The Sangeet Tales"}
          shortName={`Madhurika`}
        />

        <TeamMember
          image={images.suda_dp_sangeettales}
          name={"Sudarsan Prabakaran"}
          mywords={` , the man behind this website, is an experienced web designer and developer with 7+ years of industry expertise. As a Lead Developer, he excels in UI/UX design, application development, and cloud hosting.
          In addition to creating RSVP invites, Sudarsan offers a range of other tech-related services for weddings. This includes designing wedding websites, creating personalized guest lists and seating charts, developing interactive photo galleries, and implementing online gift registries. His comprehensive skill set allows couples to streamline their wedding planning process and create a cohesive and engaging digital presence for their special day.`}
          title={"Digital partner | The Sangeet Tales"}
          shortName={`Sudarsan`}
        />

        <div className="app__specialMenu-title">
          <h1 className="headtext__cormorant" id="weather_boys">
            All Weather boys
          </h1>
          {/* <img src={images.spoon} alt="spoon_image" className="spoon__img" /> */}
        </div>

        <TeamMember
          image={images.sabarish_varun}
          name={"Varun Subramanian & Sabarish Ganesh"}
          mywords={` are Sangeet Tales's biggest critics as well as well-wishers! We also drag them into our Couple Dance Practice Videos and give them the role of all the Non-Dancer Husbands out there! They help us in expanding our base by pitching in creative ideas & strategies!`}
          title={"Strategy and Dancer | The Sangeet Tales"}
          shortName={`Sabarish and Varun`}
        />
      </div>
    </>
  );
};

export default Team;
