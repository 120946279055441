import ReactPlayer from "react-player";

const VideoBackground = ({ url }) => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        marginTop: "90px",
        opacity: 0.15,
        width: "100%",
      }}
    >
      <ReactPlayer
        url={url}
        // url="https://sangeettales-videos.s3.ap-south-1.amazonaws.com/ROUGH.mp4"
        playing={true}
        loop={true}
        muted={true}
        width="100%"
        height="100%"
      />
    </div>
  );
};

export default VideoBackground;
