import React, { useState } from "react";

import SubHeading from "../SubHeading/SubHeading";
import "./ContactUs.css";

const ContactUs = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [userContacted, setUserContacted] = useState(false);

  const handleSubmit = async event => {
    event.preventDefault();

    try {
      const response = await fetch(
        "https://bca3p0fqmc.execute-api.ap-south-1.amazonaws.com/DEV/send-email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            message: `
          ${message} , \n
          Phone: ${phoneNumber}
          Email: ${email} 
          `,
            subject: `Enquiry from ${email}`
          })
        }
      );

      // Do something with the response, e.g. display a success message
      if (response.status === 200) {
        setUserContacted(true);
      }
    } catch (error) {
      // Handle the error, e.g. display an error message
      console.error("Error while submitting the form:", error);
    }
  };

  return (
    <div className="app__newsletter">
      <div className="app__newsletter-heading">
        {/* <SubHeading title="Contact Us" /> */}
        <h1 className="headtext__cormorant">Drop a Message</h1>
        {/* <p className="p__opensans">And We'll reach out to you ASAP!!!</p> */}
      </div>
  
      <div className="app__newsletter-input flex__center">
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Enter your email address"
            onChange={e => setEmail(e.target.value)}
            required
          />
          <input
            type="tel"
            id="phone"
            pattern="^(\+\d{1,3}\s)?\d{10}$"
            placeholder="Enter your Phone Number"
            // value={phoneNumber}
            onChange={e => setPhoneNumber(e.target.value)}
            onInvalid={e => e.target.setCustomValidity('Please enter a valid phone number (9xxxxxxxxx)')}
            onInput={e => e.target.setCustomValidity('')}
          />
          <input
            type="text"
            placeholder="Please share us what you need"
            onChange={e => setMessage(e.target.value)}
            style={{ marginTop: "5px" }}
            required
          />
          {/* <textarea placeholder="Please share us what you need"
          onChange={(e) => setMessage(e.target.value)} /> */}

          {!userContacted && (
            <button type="submit" className="custom__button">
              Send
            </button>
          )}

          {userContacted && (
            <span style={{ color: "white" }}>Message Sent!!</span>
          )}
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
