import images from "./images";

const WeddingEntertainment1 = [
  "Event planning and management",
  "Wedding Dance Choreography and Training ",
  "Grand / Dramatic couple entry",
  "Emcee / Anchoring",
  "DJ, Videography, Make up ",
  "Games & Surprises",
  "Nostalgic Couple Childhood Videos",
  "Other customised Agendas"
  
];

const WeddingEntertainment2 = [
  "Multi-Genre Solo & Group Choreography",
  "Practice videos",
  "Online / personal training",
  "Audio edits",
  "Other customisations"
];

const Variety = [
  "Multi-Genre dance program",
  "Varamala dance",
  "Wedding Lipdup",
  "Entry dance",
  "Reception entertainment program",
  "Light Music Band"
];

const WeddingCoordination = [
  "Vendor coordination",
  "Logistics management",
  "Hospitality management",
  "Event planning and theme consultancy",
  "Make up artist, DJ, Decor",
  "Event supervision",
  "Extended Family Representation"
];

const galleryImages = [
  { index: 0, src: images.mg_sangeetales_1 },
  { index: 1, src: images.mg_sangeetales_2 },
  { index: 2, src: images.mg_sangeetales_3 },
  { index: 3, src: images.mg_sangeetales_4 },
  { index: 4, src: images.mg_sangeetales_5 },

  { index: 5, src: images.mg_sangeetales_6 },
  { index: 6, src: images.mg_sangeetales_7 },
  { index: 7, src: images.mg_sangeetales_8 },
  { index: 8, src: images.mg_sangeetales_9 },
  { index: 9, src: images.mg_sangeetales_10 },

  { index: 10, src: images.mg_sangeetales_11 },
  { index: 11, src: images.mg_sangeetales_12 },
  { index: 12, src: images.mg_sangeetales_13 },
  { index: 13, src: images.mg_sangeetales_14 },
  { index: 14, src: images.mg_sangeetales_15 },

  { index: 15, src: images.mg_sangeetales_16 },
  { index: 16, src: images.mg_sangeetales_17 },
  { index: 17, src: images.mg_sangeetales_18 },
  { index: 18, src: images.mg_sangeetales_19 },
  { index: 19, src: images.mg_sangeetales_20 },

  { index: 20, src: images.mg_sangeetales_21 },
  { index: 21, src: images.mg_sangeetales_22 },
  { index: 22, src: images.mg_sangeetales_23 },
  { index: 23, src: images.mg_sangeetales_24 },
  { index: 24, src: images.mg_sangeetales_25 },

  { index: 25, src: images.mg_sangeetales_26 },
  { index: 26, src: images.mg_sangeetales_27 },
  { index: 27, src: images.mg_sangeetales_28 },
  { index: 28, src: images.mg_sangeetales_29 },
  { index: 29, src: images.mg_sangeetales_30 },

  { index: 30, src: images.mg_sangeetales_31 },
  { index: 31, src: images.mg_sangeetales_32 },
  { index: 32, src: images.mg_sangeetales_33 },
  { index: 33, src: images.mg_sangeetales_34 },
  { index: 34, src: images.mg_sangeetales_35 }
];

const cocktails = [
  {
    title: "Aperol Sprtiz",
    price: "$20",
    tags: "Aperol | Villa Marchesi prosecco | soda | 30 ml"
  },
  {
    title: "Dark 'N' Stormy",
    price: "$16",
    tags: "Dark rum | Ginger beer | Slice of lime"
  },
  {
    title: "Daiquiri",
    price: "$10",
    tags: "Rum | Citrus juice | Sugar"
  },
  {
    title: "Old Fashioned",
    price: "$31",
    tags: "Bourbon | Brown sugar | Angostura Bitters"
  },
  {
    title: "Negroni",
    price: "$26",
    tags: "Gin | Sweet Vermouth | Campari | Orange garnish"
  }
];

const awards = [
  {
    imgUrl: images.award02,
    title: "Bib Gourmond",
    subtitle: "Lorem ipsum dolor sit amet, consectetur."
  },
  {
    imgUrl: images.award01,
    title: "Rising Star",
    subtitle: "Lorem ipsum dolor sit amet, consectetur."
  },
  {
    imgUrl: images.award05,
    title: "AA Hospitality",
    subtitle: "Lorem ipsum dolor sit amet, consectetur."
  },
  {
    imgUrl: images.award03,
    title: "Outstanding Chef",
    subtitle: "Lorem ipsum dolor sit amet, consectetur."
  }
];

export default {
  WeddingEntertainment1,
  WeddingEntertainment2,
  Variety,
  WeddingCoordination,
  cocktails,
  awards,
  galleryImages
};
